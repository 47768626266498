import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js'; // Asegúrate de especificar la extensión del archivo aquí
import reportWebVitals from './reportWebVitals.js';
import axios from 'axios';

// Configuración de Axios
axios.defaults.baseURL = 'http://localhost:3000';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Si quieres empezar a medir el rendimiento de tu aplicación, pasa una función
// para registrar los resultados (por ejemplo: reportWebVitals(console.log))
// o envíalos a un endpoint de análisis. Aprende más: https://bit.ly/CRA-vitals
reportWebVitals();
