// OtroComponente.js
import React from 'react';
import './OtroComponente.css'; // Importa el archivo de estilos CSS
import PatientDetails from './PatientDetails.js'; // Asegúrate de que la ruta sea correcta

const pacienteEjemplo = {
  name: 'Juan Pérez',
  age: 45,
  // Otros datos del paciente...
};

const OtroComponente = () => {
  return (
    <div className="otro-componente-container">
      <div className="otro-componente-header">
        <h1>Ejemplo de Otro Componente</h1>
      </div>
      <div className="otro-componente-content">
        <h2>Ejemplo de Detalles del Paciente</h2>
        <PatientDetails patient={pacienteEjemplo} />
      </div>
      <div className="otro-componente-footer">
        <button>Guardar</button>
      </div>
    </div>
  );
};

export default OtroComponente;
