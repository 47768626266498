import React from 'react';
import './MedicalRecords.css'; // Importa el archivo de estilos CSS

const MedicalRecords = () => {
  return (
    <div className="medical-records-container">
      <div className="record-item">
        <h3>Registro Médico 1</h3>
        <p>Fecha: 2024-06-26</p>
        <p>Médico: Dr. Juan Pérez</p>
        <p className="description">Descripción: Lorem ipsum dolor sit amet, consectetur adipiscing elit...</p>
      </div>
      <div className="record-item">
        <h3>Registro Médico 2</h3>
        <p>Fecha: 2024-06-25</p>
        <p>Médico: Dra. María Gómez</p>
        <p className="description">Descripción: Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua...</p>
      </div>
      {/* Más registros médicos */}
    </div>
  );
};

export default MedicalRecords;
