import React from 'react';
import './PatientList.css'; // Importa el archivo de estilos CSS

const PatientDetails = ({ patient }) => {
  return (
    <div className="patient-details">
      <h2>Detalles del Paciente</h2>
      {patient ? (
        <div>
          <p><strong>Nombre:</strong> {patient.name}</p>
          <p><strong>Edad:</strong> {patient.age}</p>
          {/* Agrega más detalles del paciente según tus necesidades */}
        </div>
      ) : (
        <p>No se encontraron detalles del paciente.</p>
      )}
    </div>
  );
};

export default PatientDetails;

