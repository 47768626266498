import React from 'react';
import './PatientList.css'; // Importa el archivo de estilos CSS

const PatientList = ({ patients }) => {
  return (
    <div className="patient-list-container">
      {patients.map(patient => (
        <div key={patient.id} className="patient-item">
          <h3>{patient.name}</h3>
          <p>Edad: {patient.age}</p>
          <p className="details">Detalles adicionales: {patient.details}</p>
        </div>
      ))}
    </div>
  );
};

export default PatientList;

