// src/components/PatientForm.js
import React, { useState } from 'react';
import axios from 'axios';

function PatientForm() {
  const [formData, setFormData] = useState({
    name: '',
    age: '',
    condition: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3000/api/patients', formData);
      console.log('Datos enviados:', response.data);
    } catch (error) {
      console.error('Error al enviar los datos:', error);
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div>
          <label>
            Nombre:
            <input type="text" name="name" value={formData.name} onChange={handleChange} />
          </label>
        </div>
        <div>
          <label>
            Edad:
            <input type="number" name="age" value={formData.age} onChange={handleChange} />
          </label>
        </div>
        <div>
          <label>
            Condición:
            <input type="text" name="condition" value={formData.condition} onChange={handleChange} />
          </label>
        </div>
        <button type="submit">Enviar</button>
      </form>
    </div>
  );
}

export default PatientForm;
